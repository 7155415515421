import {
  Button,
  Col,
  Input,
  Layout,
  Row,
  Skeleton,
  Space,
  Typography,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import WalletHeader from "../component/walletHeader";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { updateInitial } from "../store/user";

const { Text } = Typography;

export default function TwoDSelect() {
  const { window_height, select2D, select_agent } = useSelector(
    (state) => state.initial
  );
  const dispatch = useDispatch();
  const [error, isError] = useState(false);
  const navigate = useNavigate();
  const [isQuick, setIsQuick] = useState(false);
  const [minAmount, setMinAmount] = useState(100);
  const [selectAmount, setSelectAmount] = useState(0);

  useEffect(() => {
    let raw = [];

    if (select_agent && select_agent.length > 0 && select2D.length !== 100) {
      raw = JSON.parse(select_agent[0].twod).map((two) => ({
        key: two.key,
        number: two.key < 10 ? `0${two.key}` : `${two.key}`,
        min_bit: two.min_bit,
        max_bit: two.max_bit,
        max: two.max,
        current: two.current,
        avilableAmount: two.max - two.current,
        percent: (two.current / two.max) * 100,
        amount: 0,
        is_select: false,
      }));

      setMinAmount(raw[0].min_bit);

      dispatch(
        updateInitial({
          select2D: raw.sort((a, b) => a.key - b.key),
        })
      );
    }
  }, []);

  function pregressColorHandler(percent) {
    switch (true) {
      case percent > 50 && percent < 75:
        return `#F9A21C ${percent}%`;
      case percent > 75:
        return `#F91C1C ${percent}%`;
      default:
        return `#34AF83 ${percent}%`;
    }
  }

  function rHandler() {
    const keys = select2D
      .filter((value) => value.is_select)
      .map((value) => value.number);

    dispatch(
      updateInitial({
        select2D: select2D.map((value) =>
          !!keys.find(
            (key) =>
              value.number === key ||
              value.number.split("").reverse().join("") === key
          )
            ? {
                ...value,
                is_select: value.avilableAmount >= minAmount ? true : false,
              }
            : value
        ),
      })
    );
  }

  function updateHandler(keys) {
    dispatch(
      updateInitial({
        select2D: select2D.map((value) =>
          !!keys.find((key) => key === value.number)
            ? {
                ...value,
                is_select: value.avilableAmount >= minAmount ? true : false,
              }
            : value
        ),
      })
    );

    setIsQuick(false);
  }

  function selectAreaHandler() {
    return isQuick ? (
      <Row gutter={[14, 14]}>
        <Col span={24}>
          <Text
            strong
            style={{ fontSize: "1.2rem", color: "#34AF83", fontWeight: "700" }}
          >
            အထူး
          </Text>
        </Col>
        <Col span={12}>
          <div
            onClick={() =>
              updateHandler([
                "05",
                "61",
                "72",
                "83",
                "94",
                "50",
                "16",
                "27",
                "38",
                "48",
              ])
            }
            style={{
              background: "#181f25",
              padding: "12px 0px",
              borderRadius: "14px",
              width: "100%",
            }}
          >
            <Row justify={"center"}>
              <Col>
                <Text strong>ပါဝါ</Text>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={12}>
          <div
            onClick={() =>
              updateHandler([
                "07",
                "18",
                "24",
                "35",
                "69",
                "70",
                "81",
                "42",
                "53",
                "96",
              ])
            }
            style={{
              background: "#181f25",
              padding: "12px 0px",
              borderRadius: "14px",
              width: "100%",
            }}
          >
            <Row justify={"center"}>
              <Col>
                <Text strong>နတ်ခတ်</Text>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={12}>
          <div
            onClick={() => updateHandler(["13", "36", "47", "58", "09"])}
            style={{
              background: "#181f25",
              padding: "12px 0px",
              borderRadius: "14px",
              width: "100%",
            }}
          >
            <Row justify={"center"}>
              <Col>
                <Text strong>ထိုင်းပါဝါ</Text>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={12}>
          <div
            onClick={() => updateHandler(["19", "23", "48", "56", "07"])}
            style={{
              background: "#181f25",
              padding: "12px 0px",
              borderRadius: "14px",
              width: "100%",
            }}
          >
            <Row justify={"center"}>
              <Col>
                <Text strong>ထိုင်းနတ်ခတ်</Text>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={12}>
          <div
            onClick={() =>
              updateHandler([
                "01",
                "12",
                "23",
                "34",
                "45",
                "56",
                "67",
                "78",
                "89",
                "90",
              ])
            }
            style={{
              background: "#181f25",
              padding: "12px 0px",
              borderRadius: "14px",
              width: "100%",
            }}
          >
            <Row justify={"center"}>
              <Col>
                <Text strong>ညီအစ်ကို</Text>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={12}>
          <div
            onClick={() =>
              updateHandler([
                "00",
                "11",
                "22",
                "33",
                "44",
                "55",
                "66",
                "77",
                "88",
                "99",
              ])
            }
            style={{
              background: "#181f25",
              padding: "12px 0px",
              borderRadius: "14px",
              width: "100%",
            }}
          >
            <Row justify={"center"}>
              <Col>
                <Text strong>အပူး</Text>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={12}>
          <div
            onClick={() => updateHandler(["00", "22", "44", "66", "88"])}
            style={{
              background: "#181f25",
              padding: "12px 0px",
              borderRadius: "14px",
              width: "100%",
            }}
          >
            <Row justify={"center"}>
              <Col>
                <Text strong>စုံစုံ</Text>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={12}>
          <div
            onClick={() => updateHandler(["11", "33", "55", "77", "99"])}
            style={{
              background: "#181f25",
              padding: "12px 0px",
              borderRadius: "14px",
              width: "100%",
            }}
          >
            <Row justify={"center"}>
              <Col>
                <Text strong>မမ</Text>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={12}>
          <div
            onClick={() =>
              updateHandler([
                "20",
                "21",
                "23",
                "25",
                "27",
                "29",
                "41",
                "43",
                "45",
                "47",
                "49",
                "40",
                "60",
                "61",
                "63",
                "65",
                "67",
                "69",
                "80",
                "81",
                "83",
                "85",
                "87",
                "89",
              ])
            }
            style={{
              background: "#181f25",
              padding: "12px 0px",
              borderRadius: "14px",
              width: "100%",
            }}
          >
            <Row justify={"center"}>
              <Col>
                <Text strong>စုံမ</Text>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={12}>
          <div
            onClick={() =>
              updateHandler([
                "02",
                "04",
                "06",
                "08",
                "12",
                "14",
                "16",
                "18",
                "32",
                "34",
                "36",
                "38",
                "52",
                "54",
                "56",
                "58",
                "72",
                "74",
                "76",
                "78",
                "92",
                "94",
                "96",
                "98",
              ])
            }
            style={{
              background: "#181f25",
              padding: "12px 0px",
              borderRadius: "14px",
              width: "100%",
            }}
          >
            <Row justify={"center"}>
              <Col>
                <Text strong>မစုံ</Text>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={24} style={{ paddingTop: "12px" }}>
          <Text
            strong
            style={{ fontSize: "1.2rem", color: "#34AF83", fontWeight: "700" }}
          >
            ပါတ်သီး
          </Text>
        </Col>
        <Col span={24}>
          <Row gutter={[6, 12]}>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "00",
                    "01",
                    "02",
                    "03",
                    "04",
                    "05",
                    "06",
                    "07",
                    "08",
                    "09",
                    "10",
                    "20",
                    "30",
                    "40",
                    "50",
                    "60",
                    "70",
                    "80",
                    "90",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  0
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "01",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "11",
                    "21",
                    "31",
                    "41",
                    "51",
                    "61",
                    "71",
                    "81",
                    "91",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  1
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "02",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "12",
                    "22",
                    "32",
                    "42",
                    "52",
                    "62",
                    "72",
                    "82",
                    "92",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  2
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "03",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "36",
                    "37",
                    "38",
                    "39",
                    "13",
                    "23",
                    "33",
                    "43",
                    "53",
                    "63",
                    "73",
                    "83",
                    "93",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  3
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "04",
                    "41",
                    "42",
                    "43",
                    "44",
                    "45",
                    "46",
                    "47",
                    "48",
                    "49",
                    "14",
                    "24",
                    "34",
                    "44",
                    "54",
                    "64",
                    "74",
                    "84",
                    "94",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  4
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "05",
                    "51",
                    "52",
                    "53",
                    "54",
                    "55",
                    "56",
                    "57",
                    "58",
                    "59",
                    "15",
                    "25",
                    "35",
                    "45",
                    "55",
                    "65",
                    "75",
                    "85",
                    "95",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  5
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "06",
                    "61",
                    "62",
                    "63",
                    "64",
                    "65",
                    "66",
                    "67",
                    "68",
                    "69",
                    "16",
                    "26",
                    "36",
                    "46",
                    "56",
                    "66",
                    "76",
                    "86",
                    "96",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  6
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "07",
                    "71",
                    "72",
                    "73",
                    "74",
                    "75",
                    "76",
                    "77",
                    "78",
                    "79",
                    "17",
                    "27",
                    "37",
                    "47",
                    "57",
                    "67",
                    "77",
                    "87",
                    "97",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  7
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "08",
                    "81",
                    "82",
                    "83",
                    "84",
                    "85",
                    "86",
                    "87",
                    "88",
                    "89",
                    "18",
                    "28",
                    "38",
                    "48",
                    "58",
                    "68",
                    "78",
                    "88",
                    "98",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  8
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "09",
                    "91",
                    "92",
                    "93",
                    "94",
                    "95",
                    "96",
                    "97",
                    "98",
                    "99",
                    "19",
                    "29",
                    "39",
                    "49",
                    "59",
                    "69",
                    "79",
                    "89",
                    "99",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  9
                </Text>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{ paddingTop: "12px" }}>
          <Text
            strong
            style={{ fontSize: "1.2rem", color: "#34AF83", fontWeight: "700" }}
          >
            ထိပ်စီး
          </Text>
        </Col>
        <Col span={24}>
          <Row gutter={[6, 12]}>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "00",
                    "01",
                    "02",
                    "03",
                    "04",
                    "05",
                    "06",
                    "07",
                    "08",
                    "09",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  0
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  1
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "20",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  2
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "36",
                    "37",
                    "38",
                    "39",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  3
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "40",
                    "41",
                    "42",
                    "43",
                    "44",
                    "45",
                    "46",
                    "47",
                    "48",
                    "49",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  4
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "50",
                    "51",
                    "52",
                    "53",
                    "54",
                    "55",
                    "56",
                    "57",
                    "58",
                    "59",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  5
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "60",
                    "61",
                    "62",
                    "63",
                    "64",
                    "65",
                    "66",
                    "67",
                    "68",
                    "69",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  6
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "70",
                    "71",
                    "72",
                    "73",
                    "74",
                    "75",
                    "76",
                    "77",
                    "78",
                    "79",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  7
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "80",
                    "81",
                    "82",
                    "83",
                    "84",
                    "85",
                    "86",
                    "87",
                    "88",
                    "89",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  8
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "90",
                    "91",
                    "92",
                    "93",
                    "94",
                    "95",
                    "96",
                    "97",
                    "98",
                    "99",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  9
                </Text>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{ paddingTop: "12px" }}>
          <Text
            strong
            style={{ fontSize: "1.2rem", color: "#34AF83", fontWeight: "700" }}
          >
            နောက်ပိတ်
          </Text>
        </Col>
        <Col span={24}>
          <Row gutter={[6, 12]}>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "00",
                    "10",
                    "20",
                    "30",
                    "40",
                    "50",
                    "60",
                    "70",
                    "80",
                    "90",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  0
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "01",
                    "11",
                    "21",
                    "31",
                    "41",
                    "51",
                    "61",
                    "71",
                    "81",
                    "91",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  1
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "02",
                    "12",
                    "22",
                    "32",
                    "42",
                    "52",
                    "62",
                    "72",
                    "82",
                    "92",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  2
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "03",
                    "13",
                    "23",
                    "33",
                    "43",
                    "53",
                    "63",
                    "73",
                    "83",
                    "93",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  3
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "04",
                    "14",
                    "24",
                    "34",
                    "44",
                    "54",
                    "64",
                    "74",
                    "84",
                    "94",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  4
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "05",
                    "15",
                    "25",
                    "35",
                    "45",
                    "55",
                    "65",
                    "75",
                    "85",
                    "95",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  5
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "06",
                    "16",
                    "26",
                    "36",
                    "46",
                    "56",
                    "66",
                    "76",
                    "86",
                    "96",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  6
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "07",
                    "17",
                    "27",
                    "37",
                    "47",
                    "57",
                    "67",
                    "77",
                    "87",
                    "97",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  7
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "08",
                    "18",
                    "28",
                    "38",
                    "48",
                    "58",
                    "68",
                    "78",
                    "88",
                    "98",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  8
                </Text>
              </div>
            </Col>
            <Col flex={"10%"}>
              <div
                onClick={() =>
                  updateHandler([
                    "09",
                    "19",
                    "29",
                    "39",
                    "49",
                    "59",
                    "69",
                    "79",
                    "89",
                    "99",
                  ])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Text strong style={{ fontWeight: "800" }}>
                  9
                </Text>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{ paddingTop: "12px" }}>
          <Text
            strong
            style={{ fontSize: "1.2rem", color: "#34AF83", fontWeight: "700" }}
          >
            ဘရိတ်
          </Text>
        </Col>
        <Col span={24} style={{ paddingBottom: "100px" }}>
          <Row gutter={[12, 12]}>
            <Col flex={"20%"}>
              <div
                onClick={() =>
                  updateHandler(["19", "28", "37", "46", "55", "00"])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "12px",
                  textAlign: "center",
                }}
              >
                <Text strong>0/10</Text>
              </div>
            </Col>
            <Col flex={"20%"}>
              <div
                onClick={() => updateHandler(["01", "29", "38", "47", "56"])}
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "12px",
                  textAlign: "center",
                }}
              >
                <Text strong>1/11</Text>
              </div>
            </Col>
            <Col flex={"20%"}>
              <div
                onClick={() => updateHandler(["02", "39", "48", "57", "66"])}
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "12px",
                  textAlign: "center",
                }}
              >
                <Text strong>2/12</Text>
              </div>
            </Col>
            <Col flex={"20%"}>
              <div
                onClick={() => updateHandler(["03", "12", "49", "58", "67"])}
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "12px",
                  textAlign: "center",
                }}
              >
                <Text strong>3/13</Text>
              </div>
            </Col>
            <Col flex={"20%"}>
              <div
                onClick={() =>
                  updateHandler(["04", "13", "57", "68", "22", "77"])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "12px",
                  textAlign: "center",
                }}
              >
                <Text strong>4/14</Text>
              </div>
            </Col>
            <Col flex={"20%"}>
              <div
                onClick={() => updateHandler(["05", "14", "23", "69", "78"])}
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "12px",
                  textAlign: "center",
                }}
              >
                <Text strong>5/15</Text>
              </div>
            </Col>
            <Col flex={"20%"}>
              <div
                onClick={() =>
                  updateHandler(["06", "15", "24", "79", "33", "88"])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "12px",
                  textAlign: "center",
                }}
              >
                <Text strong>6/16</Text>
              </div>
            </Col>
            <Col flex={"20%"}>
              <div
                onClick={() => updateHandler(["07", "16", "25", "34", "89"])}
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "12px",
                  textAlign: "center",
                }}
              >
                <Text strong>7/17</Text>
              </div>
            </Col>
            <Col flex={"20%"}>
              <div
                onClick={() =>
                  updateHandler(["08", "17", "26", "35", "44", "99"])
                }
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "12px",
                  textAlign: "center",
                }}
              >
                <Text strong>8/18</Text>
              </div>
            </Col>
            <Col flex={"20%"}>
              <div
                onClick={() => updateHandler(["09", "18", "27", "36", "45"])}
                style={{
                  padding: "10px 0",
                  background: "#181f25",
                  borderRadius: "12px",
                  textAlign: "center",
                }}
              >
                <Text strong>9/19</Text>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    ) : (
      <Row gutter={[16, 16]} style={{ transition: "2s easein" }}>
        {select2D.map((value, index) => (
          <Col flex={"20%"} key={`2d_${value.key}`}>
            <div
              onClick={() => {
                const copy2d = select2D.map((value) => value);
                const target = copy2d.find((coppy) => coppy.key === value.key);

                if (target.avilableAmount > 0) {
                  copy2d[value.key] = {
                    ...target,
                    amount: 0,
                    is_select: !target.is_select,
                  };
                  dispatch(
                    updateInitial({
                      select2D: [...copy2d],
                    })
                  );
                }
              }}
              style={{
                opacity: value.avilableAmount < 100 ? "40%" : "100%",
                borderRadius: "12px",
                background: value.is_select ? "#fff" : "#181F25",
                padding: "12px 6px",
              }}
            >
              <Row justify={"center"} gutter={[0, 4]}>
                <Col>
                  <Text
                    style={{ color: value.is_select ? "#000" : "#fff" }}
                    strong
                  >
                    {value.number}
                  </Text>
                </Col>
                <Col span={24}>
                  <div
                    style={{
                      height: "4px",
                      width: "100%",
                      borderRadius: "2px",
                      background:
                        value.percent === 100
                          ? "#F91C1C"
                          : `linear-gradient(to right,${pregressColorHandler(
                              value.percent
                            )},#434F5D ${value.percent}%)`,
                    }}
                  ></div>
                </Col>
              </Row>
            </div>
          </Col>
        ))}
      </Row>
    );
  }

  return (
    <div style={{ height: window_height, width: "100vw", overflow: "hidden" }}>
      <Layout>
        <Layout.Content>
          <WalletHeader />
          <div
            style={{
              height: `${window_height - 60}px`,
              overflowY: "auto",
              overflowX: "hidden",
              background: "#1d262fff",
              scrollbarWidth: "none",
            }}
          >
            {select2D.length === 0 ? (
              <div style={{ padding: "16px" }}>
                <Skeleton active={true} />
              </div>
            ) : (
              <Row
                gutter={[24, 24]}
                style={{
                  padding: "16px",
                }}
              >
                <Col span={24}>
                  <Space.Compact style={{ width: "100%" }}>
                    <Input
                      size="large"
                      style={{
                        borderTopLeftRadius: "12px",
                        borderBottomLeftRadius: "12px",
                        border: "none",
                        backgroundColor: "#2A3642",
                      }}
                      value={selectAmount}
                      placeholder={`အနဲဆုံးထိုးငွေ ${minAmount}`}
                      onChange={(e) => {
                        setSelectAmount(+e.target.value);
                        // dispatch(
                        //   updateInitial({ select2Damount: +e.target.value })
                        // );
                      }}
                    />
                    <Button
                      size="large"
                      onClick={() => {
                        if (selectAmount >= minAmount) {
                          dispatch(
                            updateInitial({
                              select2D: select2D.map((value) => ({
                                ...value,
                                amount: value.is_select
                                  ? selectAmount
                                  : value.amount,
                              })),
                            })
                          );
                          navigate("/twodlist");
                        }
                      }}
                      style={{
                        border: "none",
                        borderTopRightRadius: "12px",
                        borderBottomRightRadius: "12px",
                        boxShadow: "none",
                        fontWeight: "600",
                        color: "#34AF83",
                        paddingRight: "24px",
                        backgroundColor: "#181F25",
                      }}
                    >
                      ထိုးမည်
                    </Button>
                  </Space.Compact>
                </Col>
                <Col span={24}>
                  <Row justify={"space-between"}>
                    <Col
                      onClick={() => {
                        rHandler();
                      }}
                      style={{
                        padding: "12px 22px",
                        borderRadius: "10px",
                        background: "#181F25",
                      }}
                    >
                      <Text strong style={{ fontSize: "1.2rem" }}>
                        R
                      </Text>
                    </Col>
                    <Col
                      span={10}
                      style={{
                        padding: "12px 22px",
                        borderRadius: "10px",
                        background: "#181F25",
                      }}
                      onClick={() => setIsQuick(!isQuick)}
                    >
                      <Row justify={"center"}>
                        <Col>
                          <Text strong style={{ fontSize: "1.2rem" }}>
                            {isQuick ? "Normal" : "Quick"}
                          </Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      onClick={() => {
                        dispatch(
                          updateInitial({
                            select2D: select2D.map((value) => ({
                              ...value,
                              is_select: false,
                              amount: 0,
                            })),
                          })
                        );
                      }}
                      style={{
                        padding: "12px 22px",
                        borderRadius: "10px",
                        background: "#181F25",
                      }}
                    >
                      <Text strong style={{ fontSize: "1.2rem" }}>
                        Clear
                      </Text>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} style={{ marginBottom: "100px" }}>
                  {selectAreaHandler()}
                </Col>
                <Col span={24} style={{ height: "60vh" }}></Col>
              </Row>
            )}
          </div>
        </Layout.Content>
      </Layout>
    </div>
  );
}
