import { Card, Col, ConfigProvider, Row, Typography } from "antd";
import { useNavigate } from "react-router";
const { Text } = Typography;

export default function ThreeDCard() {
  const navigate = useNavigate();

  return (
    <ConfigProvider
      theme={{
        components: {
          Card: {
            colorBgContainer: "#2A3642",
          },
        },
      }}
    >
      <Card
        onClick={() => navigate('/threedhome')}
        bordered={false}
        style={{ borderRadius: "15px" }}
        styles={{ body: { padding: "8px" } }}
      >
        <Row justify={"center"}>
          <Col span={24}>
            <img style={{ width: "100%",borderRadius:'10px' }} src="/games/threed_icon.jpg" alt="ball" />
          </Col>
          <Col>
            <Text strong>Lucky Lotto (3D)</Text>
          </Col>
        </Row>
      </Card>
    </ConfigProvider>
  );
}
