import { Col, Layout, Row, Spin, message } from "antd";
import WalletHeader from "../component/walletHeader";
import AdCarousel from "../component/adCarousel";
import { useDispatch, useSelector } from "react-redux";
import TwoDCard from "../component/gameCards/2dCard";
import ThreeDCard from "../component/gameCards/3dCard";
import { useEffect, useState } from "react";
import { updateInitial } from "../store/user";
import axios from "axios";

export default function HomePage() {
  const { window_height, user_id, agent_code } = useSelector(
    (state) => state.initial
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user_id) {
      setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_SERVER_API}/v1/agent/get_user_wallet/${user_id}`
        )
        .then(({ data }) => {
          dispatch(
            updateInitial({
              main: parseInt(data.main_wallet),
              promotion: parseInt(data.promotion_wallet),
              avatar: data.avatar,
              use_wallet: data.target_point,
            })
          );
        })
        .catch((error) => {
          message.error(error.message);
        })
        .finally(() => setLoading(false));
    }
  }, []);

  return (
    <div style={{ height: window_height, width: "100vw", overflow: "hidden" }}>
      <Layout>
        <Layout.Content>
          <Spin spinning={loading}>
            <WalletHeader />
          </Spin>
          <Row
            gutter={[24, 24]}
            style={{
              height: `${window_height - 60}px`,
              overflowY: "auto",
              padding: "16px",
              background: "#1D262F",
            }}
          >
            <Col span={24} style={{ height: "max-content" }}>
              <AdCarousel />
            </Col>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <TwoDCard />
                </Col>
                <Col span={12}>
                  <ThreeDCard />
                </Col>
              </Row>
            </Col>
            <Col span={24} style={{ height: "60vh" }}></Col>
          </Row>
        </Layout.Content>
      </Layout>
    </div>
  );
}
