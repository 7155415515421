import { Col, Layout, Row, Spin, Typography, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import WalletHeader from "../component/walletHeader";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { updateInitial } from "../store/user";
import dayjs from "dayjs";
import axios from "axios";

const { Text } = Typography;

export default function TwoDNew() {
  const { window_height, user_id, agent_code, select2D } = useSelector(
    (state) => state.initial
  );
  const [todayValue, setTodayValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const times = [
    { key: "11:00:00", time: "11 AM" },
    { key: "12:01:00", time: "12 PM" },
    { key: "15:00:00", time: "3 PM" },
    { key: "16:30:00", time: "4:30 PM" },
  ];

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_SERVER_API}/v1/bet/twod_section/${user_id}/${agent_code}`
      )
      .then(({ data }) => {
        let values = [];
        for (let i of times) {
          const target = data.find((value) => value.target_time === i.key);
          if (target) {
            values = [
              ...values,
              {
                start_bit: target.bit_start_time,
                end_bit: target.bit_end_time,
                key: target.id,
                time: i.time,
                twod_agent: target ? target.twod_agent : false,
                is_done:
                  target.is_finish || target.twod_agent?.is_finish
                    ? true
                    : false,
              },
            ];
          } else {
            values = [
              ...values,
              {
                start_bit: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                end_bit: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                key: i.time,
                time: i.time,
                twod_agent: false,
                is_done: true,
              },
            ];
          }
        }

        setTodayValue(values);
      })
      .catch((error) => message.error(error.message))
      .finally(() => setLoading(false));
  }, []);

  function goToNext(key, twod_agent) {
    dispatch(
      updateInitial({
        twoDselectTime: key,
        select_agent: twod_agent,
        select2D: [],
      })
    );
    navigate("/twodselect");
  }

  function disableHandler(target) {
    const startTiem = `${dayjs().format("YYYY-MM-DD")} ${dayjs(
      target.start_bit
    ).format("HH:mm:ss")}`;
    const endTime = `${dayjs().format("YYYY-MM-DD")} ${dayjs(
      target.end_bit
    ).format("HH:mm:ss")}`;

    return (
      target.is_done || dayjs().isBefore(startTiem) || dayjs().isAfter(endTime)
    );
  }

  return (
    <div style={{ height: window_height, width: "100vw", overflow: "hidden" }}>
      <Layout>
        <Layout.Content>
          <WalletHeader />
          <Row
            gutter={[24, 24]}
            style={{
              height: `${window_height - 60}px`,
              padding: "16px",
              background: "#1D262F",
              overflowY: "auto",
            }}
          >
            <Col span={24}>
              <Spin spinning={loading}>
                <Row justify={"center"} gutter={[0, 12]}>
                  {todayValue.length > 0 && (
                    <>
                      {todayValue.map((timeNumber) => (
                        <Col
                          onClick={() => {
                            if (!disableHandler(timeNumber)) {
                              goToNext(timeNumber.key, timeNumber.twod_agent);
                            }
                          }}
                          style={{
                            background: "#181F25",
                            padding: "8px 24px",
                            borderRadius: "16px",
                          }}
                          span={24}
                          key={timeNumber.time}
                        >
                          <Row justify={"center"} align={"middle"}>
                            <Col>
                              <Text
                                disabled={disableHandler(timeNumber)}
                                style={{
                                  fontSize: "1.4rem",
                                }}
                                strong
                              >
                                {timeNumber.time}
                              </Text>
                            </Col>
                          </Row>
                        </Col>
                      ))}
                    </>
                  )}
                  <Col
                    style={{
                      background: "#181F25",
                      padding: "8px 24px",
                      borderRadius: "16px",
                    }}
                    span={24}
                  >
                    <Row justify={"center"} align={"middle"}>
                      <Col>
                        <Link to="/twod">
                          <Text
                            style={{
                              color: "#34AF83",
                              fontSize: "1.2rem",
                              margin: "4px",
                            }}
                            strong
                          >
                            နောက်သို့
                          </Text>
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Spin>
            </Col>
            <Col span={24} style={{ height: "60vh" }}></Col>
          </Row>
        </Layout.Content>
      </Layout>
    </div>
  );
}
